<template>
  <!-- <div class="container mt-1"> -->
    <div class="card mt-1">
      <div class="card-body h-100">
        <div class="row">
          <div class="col-xl-12">
            <h2 class="font-weight-bolder">เปลี่ยนรหัสผ่าน</h2>
            <!-- <div class="line mb-2" /> -->
            <hr />
            <div class="row mb-1">
              <div class="col-12 col-sm-8">
                <validation-observer ref="changepassForm">
                  <b-form class="auth-login-form" @submit.prevent>
                    <b-form-group label="รหัสผ่านปัจจุบัน*" label-cols-md="4">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="oldpassword"
                          class="form-control"
                          type="password"
                          :class="{ error: errors.length > 0 }"
                          :state="errors.length > 0 ? false : null"
                          autofocus
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >กรุณากรอกรหัสผ่านปัจจุบัน</small
                        >
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="รหัสผ่านใหม่*" label-cols-md="4">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="password"
                          type="password"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                          :class="{ error: errors.length > 0 }"
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >กรุณากรอกรหัสผ่านใหม่</small
                        >
                      </validation-provider>
                    </b-form-group>
                    <b-form-group label="ยืนยันรหัสผ่านใหม่*" label-cols-md="4">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="con_password"
                          class="form-control"
                          type="password"
                          :state="errors.length > 0 ? false : null"
                          :class="{ error: errors.length > 0 }"
                        />
                        <small v-if="errors.length > 0" class="text-danger"
                          >กรุณากรอกรหัสผ่านใหม่</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-form>
                </validation-observer>
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2">
              <b-button variant="success" size="md" @click="changePass"
                >ยืนยัน</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BModal,
  BButton,
  VBModal,
  BCardBody,
  BFormInput,
  BForm,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// import DatePicker from '../components/datePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BModal,
    BButton,
    VBModal,
    BCardBody,
    BFormInput,
    BForm,
    BInputGroupAppend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      oldpassword: "",
      newpassword: "",
      con_password: "",
      required,
    };
  },
  created() {},
  methods: {
    changePass() {
      this.$refs.changepassForm.validate().then((success) => {
        if (success) {
          if (this.newpassword !== this.con_password) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "โปรไฟล์",
                icon: "UserIcon",
                variant: "danger",
                text: "รหัสผ่านใหม่กับรหัสผ่านยืนยันไม่ตรงกัน",
              },
            });
          } else {
            const payload = {
              oldpassword: this.oldpassword,
              newpassword: this.newpassword,
              con_password: this.con_password,
            };
            this.$store
              .dispatch("user/changePass", payload)
              .then((result) => {
                if (result.data.success) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "โปรไฟล์",
                      icon: "UserIcon",
                      variant: "success",
                      text: result.data.message,
                    },
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "โปรไฟล์",
                      icon: "UserIcon",
                      variant: "danger",
                      text: result.data.message,
                    },
                  });
                }
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "โปรไฟล์",
                    icon: "UserIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  border-color: red;
}
</style>
